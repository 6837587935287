/**
 * Swiper options for multiple slides
 * Reusable options
 * */
import {
  Navigation,
  Pagination,
  Mousewheel,
  Thumbs,
  Autoplay,
  EffectFade
} from 'swiper/modules';
import type {SwiperOptions} from 'swiper/types/swiper-options';
import 'swiper/css/bundle';
import '../../../css/pages/swiper.css';

export const optionSingleMain = (
  options = {} as Object,
  next = 'swiper-next',
  prev = 'swiper-prev'
) => {
  return {
    modules: [Navigation, Pagination],
    ...options,
    slidesPerView: 1,
    loop: true,
    navigation: {
      nextEl: `.${next}`,
      prevEl: `.${prev}`,
    },
    freeMode: true,
    watchSlidesProgress: true,
    lazyPreloadPrevNext: 2,
  }
}

export const optionSyncThumbMain = (
  thumbSwiper: any,
  options = {} as Object
): SwiperOptions => {
  return {
    modules: [Thumbs, Navigation, Pagination],
    ...options,
    //speed: 500,
    slidesPerView: 1,
    loop: true,
    pagination: {
      el: '.swiper-pagination',
      dynamicBullets: true,
    },
    navigation: {
      nextEl: ".swiper-next",
      prevEl: ".swiper-prev",
      //speed: 305,
    },
    thumbs: {
      swiper: thumbSwiper,
    },
    freeMode: true,
    watchSlidesProgress: true,
    lazyPreloadPrevNext: 2,
  } as SwiperOptions
}

export const optionThumbMain = (
  options = {} as Object
): SwiperOptions => {
  return {
    ...options,
    loop: true,
    spaceBetween: 2,
    slidesPerView: 5,
    breakpoints: {
      324: {
        slidesPerView: 3,
        spaceBetween: 2,
      },
      490: {
        slidesPerView: 4,
        spaceBetween: 2,
      },
      768: {
        slidesPerView: 5,
        spaceBetween: 2,
      },
      1024: {
        slidesPerView: 5,
        spaceBetween: 2,
      },
    },
    freeMode: true,
    watchSlidesProgress: true,
    lazyPreloadPrevNext: 1
  }
}

export const optionThumbFullscreen = (
  options = {} as Object
): SwiperOptions => {
  return {
    ...options,
    spaceBetween: 5,
    slidesPerView: 5,
    breakpoints: {
      324: {
        slidesPerView: 3,
        spaceBetween: 3,
      },
      490: {
        slidesPerView: 3,
        spaceBetween: 3,
      },
      768: {
        slidesPerView: 5,
        spaceBetween: 5,
      },
      1024: {
        slidesPerView: 5,
        spaceBetween: 5,
      },
    },
  }
}

export const optionSyncThumbFullscreen = (
  name: string,
  thumbSwiper: any,
  options = {} as Object
): SwiperOptions => {
  return {
    ...options,
    modules: [Navigation, Pagination, Thumbs, Mousewheel],
    slidesPerView: 1,
    pagination: {
      el: '.fullscreen-pagination',
      type: 'fraction',
    },
    navigation: {
      nextEl: `.swiper-${name}-fullscreen-next`,
      prevEl: `.swiper-${name}-fullscreen-prev`,
      //speed: 305,
    },
    thumbs: {
      swiper: thumbSwiper,
    },
    watchSlidesProgress: true,
    lazyPreloadPrevNext: 2,
    mousewheel: true,
  } as SwiperOptions
}

export const optionHeroAutoPlayFadeEffect = (
  options = {} as Object
): SwiperOptions => {
  return {
    modules: [Autoplay, EffectFade],
    ...options,
    slidesPerView: 1,
    freeMode: true,
    loop: true,
    lazy: true,
    loadPrevNext: true,
    loadPrevNextAmount: 1,
    effect: 'fade',
    crossFade: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
  } as SwiperOptions
}


export default {
  optionSingleMain,
  optionThumbMain,
  optionThumbFullscreen,
  optionSyncThumbFullscreen,
  optionSyncThumbMain,
  optionHeroAutoPlayFadeEffect
};

